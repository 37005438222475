

































import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import NavMenu from '@/partials/components/NavMenu.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

const eventsPermissionsModule = namespace('eventsPermissions')

@Component({
  components: {
    NavMenu
  }
})
export default class EventsShowLayout extends Mixins(PermissionsMixin) {
  @eventsPermissionsModule.Getter isSubscriberOnEvent!: (eventId: string) => boolean
  availableRoutes: AvailableRoute[] = []
  copyLinkButtonText = 'Copiar Link'

  created () {
    this.availableRoutes = [
      {
        label: 'Resumo',
        path: this.routeFormatted('')
      },
      {
        label: 'Inscritos',
        path: this.routeFormatted('/subscriptions'),
        permission: { id: this.$route.params.id, name: this.permissions.EVENTSUBSCRIPTION.READ }
      },
      {
        label: 'Trabalhos Científicos',
        path: this.routeFormatted('/studies'),
        permission: { id: this.$route.params.id, name: this.permissions.EVENTSTUDY.READ }
      },
      {
        label: 'Programação',
        path: this.routeFormatted('/schedules'),
        permission: { id: this.$route.params.id, name: this.permissions.EVENTACTIVITY.READ }
      },
      {
        label: 'Palestrantes',
        path: this.routeFormatted('/speakers'),
        permission: { id: this.$route.params.id, name: this.permissions.EVENTSPEAKER.READ }
      },
      {
        label: 'Patrocinadores',
        path: this.routeFormatted('/sponsors'),
        permission: { id: this.$route.params.id, name: this.permissions.EVENTSPONSOR.READ }
      }
    ]
  }

  copyEventLink () {
    const field = document.getElementById('event-link') as HTMLInputElement

    field.select()
    field.setSelectionRange(0, 255)

    document.execCommand('copy')

    this.copyLinkButtonText = 'Copiado!'
    setTimeout(() => { this.copyLinkButtonText = 'Copiar Link' }, 3000)
  }

  get eventUrl () {
    return window.location.href
  }

  private routeFormatted (path: string) {
    return `/events/${this.$route.params.id}${path}`
  }
}

// {
//   label: 'Trabalhos Científicos',
//   path: this.routeFormatted('/studies'),
//   permission: { id: this.$route.params.id, name: this.permissions.EVENTSTUDY.READ }
// },
// {
//   label: 'Programação',
//   path: this.routeFormatted('/activities'),
//   permission: { id: this.$route.params.id, name: this.permissions.EVENTACTIVITY.READ }
// },
// {
//   label: 'Palestrantes',
//   path: this.routeFormatted('/speakers'),
//   permission: { id: this.$route.params.id, name: this.permissions.EVENTSPEAKER.READ }
// },
// {
//   label: 'Equipe',
//   path: this.routeFormatted('/staff'),
//   permission: { id: this.$route.params.id, name: this.permissions.EVENTSTAFF.READ }
// },
// {
//   label: 'Patrocinadores',
//   path: this.routeFormatted('/sponsors'),
//   permission: { id: this.$route.params.id, name: this.permissions.EVENTSPONSOR.READ }
// }
